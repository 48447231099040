<template>
  <div>
    <h4 class="pb-4">Latest Water Vehicle Request</h4>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">Customer Name</th>
            <th scope="col">Volume</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="waterVehicleRequest in latestWaterVehicleRequests"
            :key="waterVehicleRequest.id"
          >
            <td> {{ waterVehicleRequest.customer_name }}</td>
            <td>
              {{
                waterVehicleRequest.water_volume +
                " " +
                waterVehicleRequest.water_unit
              }}
            </td>
            <td>{{ waterVehicleRequest.status }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <router-link to="/water-vehicle-requests"> <button type="button" class="btn btn-primary float-right view-all">  View all</button></router-link>

    
  </div>
</template>

<script>
import { freeSet } from "@coreui/icons";
import { mapGetters } from "vuex";

export default {
  freeSet,
  computed: {
    ...mapGetters("WaterVehicleRequest", ["latestWaterVehicleRequests"]),
  },
  mounted() {
    this.$store.dispatch("WaterVehicleRequest/getLatestWaterVehicleRequests");
  },
};
</script>

<style lang="scss">
div {
  h4 {
    font-size: 15px;
    font-weight: bold;
    color: #000;
  }
  .table {
    font-size: 11px;
    thead {
      tr {
        th {
          font-weight: 900;
          background: #f8f9fa;
        }
      }
    }
    tbody {
      td:nth-child(1) {
        font-weight: 900;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@media (width: 1366px) {
  div {
    button{
      margin-top: 20px;
    }
  }
}
</style>

